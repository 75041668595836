import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import './home.scss'
import { useMediaQuery } from 'react-responsive'
import { Button } from "@material-ui/core"
import Hero1 from '../svgs/hero1.svg'
import Hero1_1 from '../svgs/hero1_1.svg'
import Hero1_3 from '../svgs/hero1_3.svg'
import Circle from '../images/Circle.png'
import Map from '../components/map'
import Role1 from '../svgs/role1.svg'
import Role2 from '../svgs/role2.svg'
import Role3 from '../svgs/role3.svg'
import role4 from '../svgs/role4.svg'


const IndexPage = ({ data }) => {
  const Bigscreen = useMediaQuery({
    query: '(min-device-width: 1200px)'
  })
  const Smallscreen = useMediaQuery({
    query: '(max-device-width: 1199px)'
  })

  return (
    < div >
      <Layout>
        <SEO title="Home" />
        <div className='consept mt-section'>
          {Bigscreen &&
            <h1>思いをカタチにビジネスをデザイン</h1>}
          {Smallscreen && <h1>思いをカタチに<br />ビジネスをデザイン</h1>
          }

          {Bigscreen &&
            <h3>変化し続ける社会に適応するビジネスデザイン思考</h3>}
          {Smallscreen &&
            <h3>変化し続ける社会に適応する<br />ビジネスデザイン思考</h3>
          }
        </div>
        <div id='company-info'>
          <h2>株式会社Beyond Oneは</h2>
          <p>営利・非営利問わず事業の目的を実現するための活動を具体的に立案、設計し、
          マーケティングやブランディングなどを踏まえて、アウトプットとなる販促やデザイン及びアクションプランに
          落とし込むまでの一連の流れをステークホルダーを中心に考え、ビジネスデザインし、設計図を描くこと。
          設計図を元にビジネスリーダーと共にアクション伴走して、結果を出すことが得意な会社です。
          </p>
        </div>
        {Bigscreen &&
          <h2 className='mt-section' style={{ marginBottom: '50px' }}>
            Business Designから一貫した伴走型プロデュースが得意な会社です</h2>
        }
        {Smallscreen &&
          <h2 className='mt-section' style={{ marginBottom: '15px', fontSize: '1.9rem' }}>
            ビジネスデザインから一貫した<br />伴走型プロデュースが得意な会社です</h2>
        }
        <div className='heros'>
          <div className='hero hero1'>
            <div className='hero-text'>
              {Bigscreen &&
                <h3>構想やアイディアを『可視化・言語化』</h3>}
              {Smallscreen &&
                <h3>構想やアイディアを可視化・言語化</h3>
              }
              <p>ディスカッションの中で、リーダーの頭の中・考えてることを聴き、
              実際の環境や取り組みを視て、理解した上で、どのように展開・活動していくかを纏めていく。
              その後、事業としてやるのか意思決定の方向づけを行う。
            </p>
            </div>
            <div className='hero1-pic'>
              <img src={Hero1_1} alt='hero1' />
            </div>
          </div>

          <div className='hero hero2'>
            <div className='hero-text'>
              {Bigscreen &&
                <h3>ブランド確立の『デザイン経営戦略立案』</h3>}
              {Smallscreen &&
                <h3>ブランド確立のデザイン経営戦略立案</h3>
              }
              <p>取組み価値をブランドとしてデザインし、
            事業全体をブランディング観点を持ってBusinessデザインしていく。</p>
            </div>
            <div className='hero1-pic'>
              <img src={Hero1} alt='hero1' />
            </div>
          </div>
          <div className='hero'>
            <div className='hero-text'>
              <h3>実働を『アクション伴走』</h3>
              <p>マーケティングやブランディングなどを踏まえて、アウトプットとなる販促やデザイン及びアクションプラン
              に落とし込むまでの一連の流れをステークホルダーを中心に考え、ビジネスデザイン・設計図を描いていく。
              結果を出すための人脈やBusiness環境及びファンドを駆使して、取り組みを行う。
              軌道修正も含め、事業として成り立つようにトライ＆エラーを行う。
  </p>
            </div>
            <div className='hero1-pic'>
              <img src={Hero1_3} alt='planet' />
            </div>
          </div>
        </div>

        <div className='mt-section'>
          {Bigscreen &&
            <h2 style={{ marginBottom: '20px' }}>
              私たちは、未来をデザインする人や環境と共に育む社会を創出します</h2>}
          {Smallscreen &&
            <h2 style={{ marginBottom: '20px' }}>
              私たちは未来をデザインする<br />人や環境と共に育む社会を<br />創出します</h2>
          }
          <h3>Beyond Oneが準備するもの</h3>

          <div className='roles'>
            <div className='role' id='role1'>
              <h4>人 People</h4>
              <p>多様な生き方・働き方にむけてチャレンジするソーシャルリーダー、メンバー
              （の発掘・支援）
            </p>
              <img src={Role1} alt='people' />
            </div>
            <div className='role' id='role2'>
              <h4>環境 Environment</h4>
              <p>互いに自分らしく学びあう場所・チーム（の提供）
              Businessモデル構築やシステム及びノウハウなど知的資産を、共有財産化し、経験値
              を流用していくための『ソーシャルエデュケションプラットホーム』を構築、運用。
            </p>
              <img src={Role2} alt='environment' />
            </div>
            <div className='role' id='role3'>
              <h4>働く Work</h4>
              <p>各々が自立して豊かに生きて行ける未来を創出するためのBusiness作り共にBusinessモデルをデザイン立案、
              実働伴走、仕組み化し、ソーシャルBusiness化すること。リーダー養成・事業準備のためのアカデミーやBusiness実践を
              行っていくための各種コンテンツの企画及び制作、フロント・バックOfficeとなる業務サポート。

            </p>
              <img src={Role3} alt='work' />
            </div>
            <div className='role' id='role4'>
              <h4>仕組み System</h4>
              <p>持続可能で豊かな社会形成の一旦を担い合う社会の仕組み作り
              <br />
              ①繋ぐプラットホーム（子供から大人まで学び・活かすアクティビティを基軸）
              <br />
              ②商品・サービス開発
              <br />
              ③マーケティング
              ）
            </p>
              <img src={role4} alt='system' />
            </div>
          </div>
        </div>

        <div className='services-section mt-section'>
          <h2>事業内容</h2>
          <h3 style={{ fontWeight: 500 }}>OUR SERVICES</h3>
          <div className='services'>
            <div className='service align-center'>
              <img src={Circle} alt='circle' />
              <h4>Business Design Produce</h4>
              <Link to='#'>READ MORE</Link>
            </div>
            <div className='service align-center'>
              <img src={Circle} alt='circle' />
              <h4>Social Platform Operation</h4>
              <Link to='#'>READ MORE</Link>
            </div>
            <div className='service align-center'>
              <img src={Circle} alt='circle' />
              <h4>Front-Back office</h4>
              <Link to='#'>READ MORE</Link>
            </div>
          </div>
          <div className='services'>
            <div className='service align-center'>
              <img src={Circle} alt='circle' />
              <h4>営業ツール Design</h4>
              <Link to='#'>READ MORE</Link>
            </div>
            <div className='service align-center'>
              <img src={Circle} alt='circle' />
              <h4>Web Design</h4>
              <Link to='#'>READ MORE</Link>
            </div>
            <div className='service align-center'>
              <img src={Circle} alt='circle' />
              <h4>Business Design Produce</h4>
              <Link to='#'>READ MORE</Link>
            </div>
          </div>
        </div>
        <div className='news-section mt-section'>
          <h2>NEWS</h2>
          <div className='posts'>
            {data.allMicrocmsNews.edges.map(edge => {
              return (
                <div className='post'>
                  <h3>{edge.node.title}</h3>
                  <img src={edge.node.image.url} alt='投稿用画像' />
                  {/* {edge.node.iamge.url ? (
                    <img
                      src={edge.node.image.url}
                      alt='投稿用画像'
                    />
                  ) : (
                      <img
                        src='../images/Circle.png'
                        alt='投稿用画像'
                      />
                    )} */}
                  <Link to={`/news/${edge.node.id}`}>
                    READ MORE
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
        <div className='access-section mt-section'>
          <h2>ACCESS</h2>
          <div className='access'>
            <div className='align-center'>
              <div id='address' className='align-center' style={{ width: '100%' }}>
                <div style={{ textAlign: 'center', letterSpacing: '0.12em', lineHeight: '2em' }}>
                  福岡県福岡市中央区天神2-3-36<br />ibb fukuokaビル 5階
                  </div>
                <Button>
                  Google Map
              </Button>
              </div>
            </div>
            <Map />
          </div>
        </div>
      </Layout>
    </div >
  )
}

export const query = graphql`
 {
  allMicrocmsNews(
    sort: {fields: [createdAt], order: DESC}) {
    edges {
      node {
        id
        title
        tag {
          id
          name
        }
          image {
            url
          }
          content
        }
      }
    }
 }
`

export default IndexPage