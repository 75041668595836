import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
}

export class MapContainer extends Component {
    state = {
        selectedPlace: {}
    }

    render() {
        return (
            <Map
                className='map'
                google={this.props.google}
                zoom={16}
                center={{
                    lat: 33.587406,
                    lng: 130.398902
                }}
                initialCenter={{
                    lat: 33.587406,
                    lng: 130.398902
                }}
                containerStyle={containerStyle}
            >
                <Marker
                    title={'ibb Fukuoka ビル'}
                    name={'Ibb Fukuoka ビル'}
                    position={{ lat: 33.587406, lng: 130.398902 }} />


                <InfoWindow
                    visible={true}
                >
                    <div>
                        <h5>{this.state.selectedPlace.name}</h5>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyDMApBqe6LNjOJC5L2scCyUzKj8yh8XBgc')
})(MapContainer)
